<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Confirm Delivery Address"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="false"
          :showPie="false"
          :showWine="false"
        />
      </div>

      <div class="col-span-8">
        <div class="m-20">
          <div class="grid grid-cols-5 gap-4 m-20">
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">First Name</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  v-model="deliveryAddress.firstname"
                  name="floating_first_name"
                  id="floating_first_name"
                  type="text"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Last Name</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  v-model="deliveryAddress.surname"
                  type="text"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Telephone</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  v-model="deliveryAddress.telephone"
                  required
                ></md-input>
              </md-field>
            </div>
            <div class="col-span-5">
              <hr class="oeno_divider_light" />
            </div>
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Address</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  type="text"
                  v-model="deliveryAddress.address_line1"
                  required
                ></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10"></span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="deliveryAddress.address_line2"></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Town</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="deliveryAddress.town"></md-input>
              </md-field>
            </div>

            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">County</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  type="text"
                  v-model="deliveryAddress.county"
                  required
                ></md-input>
              </md-field>
            </div>
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Postcode</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input
                  type="text"
                  v-model="deliveryAddress.postcode"
                  required
                ></md-input>
              </md-field>
            </div>
            <div class="col-span-2">
              <span class="oeno_portfolio_winetitle pt-10">Country</span>
            </div>
            <div class="col-span-3">
              <md-field class="md-field-small">
                <md-input v-model="deliveryAddress.country"></md-input>
              </md-field>
            </div>
          </div>
          <div class="flex justify-center">
            <button
              type="submit"
              @click="submitForm()"
              class="w-72 h-10 oeno_button_black mt-10 align-center"
              >{{ updateStatus ? "Processing...." : "Continue" }}</button
            >
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

export default {
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },
  data() {
    return {
      deliveryAddress: null,
      updateStatus: false,
      orderData: null,
    };
  },
  created: function () {
    this.setDefaultData();
  },
  methods: {
    setDefaultData() {
      this.deliveryAddress = this.$store.state.orderData.deliveryAddress;
      this.orderData = this.$store.state.orderData;
    },
    submitForm() {
      this.updateStatus = true;

      this.orderData.deliveryAddress = this.deliveryAddress;

      this.$store.dispatch("storeOrderData", this.orderData).then(() => {
        this.$router.push({
          name: "page--buy--invest-pay",
          params: {
            id: this.$route.params.id,
            source: this.$route.params.source,
            sell_size: this.$route.params.sell_size,
          },
        });
      });
    },
  },
};
</script>
